<template>
  <div class="tariff-wrapper">
    <b-notification
      class="notification-wrapper"
      :active.sync="notification.show"
      :type="notification.type"
      auto-close
    >
      {{ notification.text }}
    </b-notification>

    <div class="tariff-header-panel">
      <b-button class="request-button" type="is-primary" @click="$router.push({ name: 'Tariffs' })">
        {{ $t('back') }}
      </b-button>
    </div>

    <div class="tariff-body-content">
      <div class="items-wrapper" v-if="!isLoading">
        <div class="item-input-wrapper">
          <div class="item-title">
            Название тарифа:
          </div>
          <b-field :type="errors.name ? 'is-danger' : 'text'">
            <b-input v-model="tariff.name" @input="errors.name = false"></b-input>
          </b-field>
          <div class="error-message" v-if="errors.name">
            Введите название тарифа
          </div>
        </div>

        <div class="item-input-wrapper">
          <div class="item-title">
            Цена:
          </div>
          <b-field :type="errors.price ? 'is-danger' : 'text'">
            <b-input v-model="tariff.price" @input="errors.price = false"></b-input>
          </b-field>
          <div class="error-message" v-if="errors.price">
            Только положительное число и не более 2 цифр после точки
          </div>
        </div>

        <div class="item-input-wrapper">
          <div class="item-title">
            Валюта:
          </div>

          <b-dropdown
            v-model="tariff.currency"
            class="dropdown-wrapper"
            @change="errors.currency = false"
            aria-role="list"
          >
            <template slot="trigger">
              <div :class="errors.currency ? 'select-input error' : 'select-input'">
                {{
                  tariff.currency && tariff.currency.length > 0
                    ? tariff.currency
                    : 'Выберите валюту'
                }}
              </div>
              <div class="error-message" v-if="errors.currency">
                Выберите валюту
              </div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              :value="currency"
              v-for="(currency, index) in ['RUB', 'EUR', 'USD']"
              :key="index"
            >
              {{ currency }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="item-input-wrapper">
          <div class="item-title">
            Регион:
          </div>
          <b-field :type="errors.region ? 'is-danger' : 'text'">
            <b-input v-model="tariff.region" @input="errors.region = false"></b-input>
          </b-field>
          <div class="error-message" v-if="errors.region">
            Введите название регион
          </div>
        </div>

        <div class="item-input-wrapper">
          <div class="item-title">
            Права доступа
          </div>

          <b-dropdown
            :disabled="permissions_disabled"
            v-model="tariff.permissions"
            class="dropdown-wrapper"
            aria-role="list"
            multiple
          >
            <template slot="trigger">
              <div class="select-input">
                {{
                  tariff.permissions && tariff.permissions.length > 0
                    ? tariff.permissions.join(', ')
                    : 'Выберите права доступа'
                }}
              </div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              :value="permission"
              v-for="(permission, index) in $store.getters.users_permissions"
              :key="index"
            >
              {{ permission }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="item-input-wrapper">
          <div class="item-title">
            Виджеты
          </div>

          <b-dropdown
            :disabled="permissions_disabled"
            v-model="tariff.default_instruments.widgets"
            class="dropdown-wrapper"
            aria-role="list"
            multiple
          >
            <template slot="trigger">
              <div class="select-input">
                {{
                  tariff.default_instruments.widgets &&
                  tariff.default_instruments.widgets.length > 0
                    ? tariff.default_instruments.widgets.join(', ')
                    : 'Выберите виджеты'
                }}
              </div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              :value="widget"
              v-for="(widget, index) in $store.getters.default_instruments.widgets"
              :key="index"
            >
              {{ widget }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="item-input-wrapper">
          <div class="item-title">
            Авторассылки
          </div>

          <b-dropdown
            :disabled="permissions_disabled"
            v-model="tariff.default_instruments.autocasts"
            class="dropdown-wrapper"
            aria-role="list"
            multiple
          >
            <template slot="trigger">
              <div class="select-input">
                {{
                  tariff.default_instruments.autocasts &&
                  tariff.default_instruments.autocasts.length > 0
                    ? tariff.default_instruments.autocasts.join(', ')
                    : 'Выберите авторассылки'
                }}
              </div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              :value="autocast"
              v-for="(autocast, index) in $store.getters.default_instruments.autocasts"
              :key="index"
            >
              {{ autocast }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="item-input-wrapper">
          <div class="item-title">
            Формы
          </div>

          <b-dropdown
            :disabled="permissions_disabled"
            v-model="tariff.default_instruments.forms"
            class="dropdown-wrapper"
            aria-role="list"
            multiple
          >
            <template slot="trigger">
              <div class="select-input">
                {{
                  tariff.default_instruments.forms && tariff.default_instruments.forms.length > 0
                    ? tariff.default_instruments.forms.join(', ')
                    : 'Выберите формы'
                }}
              </div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              :value="form"
              v-for="(form, index) in $store.getters.default_instruments.forms"
              :key="index"
            >
              {{ form }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="item-input-wrapper">
          <div class="item-title">
            Оповещения
          </div>

          <b-dropdown
            :disabled="permissions_disabled"
            v-model="tariff.default_instruments.alerts"
            class="dropdown-wrapper"
            aria-role="list"
            multiple
          >
            <template slot="trigger">
              <div class="select-input">
                {{
                  tariff.default_instruments.alerts && tariff.default_instruments.alerts.length > 0
                    ? tariff.default_instruments.alerts.join(', ')
                    : 'Выберите оповещения'
                }}
              </div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              :value="alert"
              v-for="(alert, index) in $store.getters.default_instruments.alerts"
              :key="index"
            >
              {{ alert }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="item-group-wrapper">
          <div class="item-title">
            Ограничения:
          </div>

          <div class="item-input-wrapper">
            <div class="item-title">
              Количество писем триггерных рассылок:
            </div>
            <b-field :type="errors.autocast_emails_count ? 'is-danger' : 'text'">
              <b-input
                v-model="tariff.limits.autocast_emails_count"
                @input="errors.autocast_emails_count = false"
              ></b-input>
            </b-field>
            <div class="error-message" v-if="errors.autocast_emails_count">
              Только целое положительное значение
            </div>
          </div>

          <div class="item-input-wrapper">
            <div class="item-title">
              Количество писем массовых рассылок:
            </div>
            <b-field :type="errors.mass_emails_count ? 'is-danger' : 'text'">
              <b-input
                v-model="tariff.limits.mass_emails_count"
                @input="errors.mass_emails_count = false"
              ></b-input>
            </b-field>
            <div class="error-message" v-if="errors.mass_emails_count">
              Только целое положительное значение
            </div>
          </div>
        </div>

        <b-button class="save-button" type="is-primary" @click="checkParamsForSave">
          {{ $t('save') }}
        </b-button>
      </div>

      <b-loading
        :is-full-page="false"
        v-if="isLoading"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>

    <b-modal :active.sync="show_update_modal">
      <div class="card">
        <div class="card-content">
          <div class="update-modal-wrapper">
            <div class="update-modal-text">
              Вы уверены что хотите обновить данные о тарифе?
            </div>
            <div class="buttons-wrapper">
              <b-button type="is-primary" @click="show_update_modal = false">
                Отмена
              </b-button>

              <b-button type="is-success" @click="updateRquest">
                Обновить
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'TariffEditor',
  data() {
    return {
      isLoading: true,
      tariff: {
        name: '',
        permissions: [],
        price: '',
        currency: '',
        region: '',
        limits: {
          autocast_emails_count: '',
          mass_emails_count: ''
        },
        default_instruments: {
          widgets: [],
          forms: [],
          alerts: [],
          autocasts: []
        }
      },
      errors: {
        name: false,
        price: false,
        currency: false,
        region: false,
        autocast_emails_count: false,
        mass_emails_count: false
      },
      permissions_disabled: true,
      notification: {
        show: false,
        text: '',
        type: 'is-success'
      },
      show_update_modal: false,
      update_request_body: {
        limits: {}
      }
    }
  },

  async mounted() {
    if (!this.$store.getters.default_instruments) {
      await this.$store.dispatch('get_default_instruments')
    }

    if (
      !this.$store.getters.users_permissions ||
      this.$store.getters.users_permissions.length === 0
    ) {
      this.getUserPermissions()
    } else {
      this.permissions_disabled = false
    }

    if (this.$router.currentRoute.name === 'TariffEdit') {
      let tariff = this.$store.getters.get_tariff_by_id(this.$router.currentRoute.params.tariff_id)
      if (!tariff) {
        await this.getRequest()
      } else {
        this.tariff = JSON.parse(JSON.stringify(tariff))
      }
    }
    this.isLoading = false
  },
  methods: {
    getUserPermissions() {
      this.isLoading = true
      this.$store.dispatch('get_users_permissions', {
        vm: this,
        callback: this.getUserPermissionsCallback
      })
    },
    getUserPermissionsCallback(request_status, response) {
      if (response.hasOwnProperty('permissions')) {
        this.permissions_disabled = false
      }
    },
    async getRequest() {
      this.isLoading = true
      try {
        const tariffFromServer = await this.$store.dispatch(
          'get_tariff',
          this.$route.params.tariff_id
        )
        this.tariff = JSON.parse(JSON.stringify(tariffFromServer))
      } catch (e) {
        console.log('Error in getRequest', e)
      }
      this.isLoading = false
    },
    validatePrice() {
      this.tariff.price = String(this.tariff.price)
      let match = this.tariff.price.match(/^\d+(\.\d{1,2})?$/g)
      if (this.tariff.price.length > 0 && match && match[0] === this.tariff.price) {
        return true
      } else {
        return false
      }
    },
    validateAutocastEmailsCount() {
      this.tariff.limits.autocast_emails_count = String(this.tariff.limits.autocast_emails_count)
      let match = this.tariff.limits.autocast_emails_count.match(/^\d+$/g)
      if (
        this.tariff.limits.autocast_emails_count.length > 0 &&
        match &&
        match[0] === this.tariff.limits.autocast_emails_count
      ) {
        return true
      } else {
        return false
      }
    },
    validateMassEmailsCount() {
      this.tariff.limits.mass_emails_count = String(this.tariff.limits.mass_emails_count)
      let match = this.tariff.limits.mass_emails_count.match(/^\d+$/g)
      if (
        this.tariff.limits.mass_emails_count.length > 0 &&
        match &&
        match[0] === this.tariff.limits.mass_emails_count
      ) {
        return true
      } else {
        return false
      }
    },
    validateTariff() {
      if (
        this.tariff.name.length > 0 &&
        this.validatePrice() &&
        this.tariff.currency.length > 0 &&
        this.tariff.region.length > 0 &&
        this.validateAutocastEmailsCount() &&
        this.validateMassEmailsCount()
      ) {
        return true
      } else {
        if (this.tariff.name.length === 0) {
          this.errors.name = true
        }
        if (!this.validatePrice()) {
          this.errors.price = true
        }
        if (this.tariff.currency.length === 0) {
          this.errors.currency = true
        }
        if (this.tariff.region.length === 0) {
          this.errors.region = true
        }
        if (!this.validateAutocastEmailsCount()) {
          this.errors.autocast_emails_count = true
        }
        if (!this.validateMassEmailsCount()) {
          this.errors.mass_emails_count = true
        }

        return false
      }
    },
    async checkParamsForSave() {
      if (this.validateTariff()) {
        if (this.$router.currentRoute.name === 'TariffNew') {
          this.isLoading = true
          await this.saveRequest()
        } else if (this.$router.currentRoute.name === 'TariffEdit') {
          this.checkNeedUpdate()
        }
      }
    },
    async saveRequest() {
      this.isLoading = true
      try {
        const tariffFromServer = await this.$store.dispatch('new_tariff', this.tariff)
        this.tariff = JSON.parse(JSON.stringify(tariffFromServer))
      } catch (e) {
        console.log('Error in saveRequest', e)
      }
      this.isLoading = false
    },
    compareStringArray(firstArray, secondArray) {
      return (
        firstArray.length === secondArray.length &&
        firstArray.sort().every((value, index) => {
          return value === secondArray.sort()[index]
        })
      )
    },
    checkNeedUpdate() {
      let tariff = this.$store.getters.get_tariff_by_id(this.$router.currentRoute.params.tariff_id)

      if (
        tariff.name !== this.tariff.name ||
        tariff.region !== this.tariff.region ||
        tariff.currency !== this.tariff.currency ||
        String(tariff.price) !== this.tariff.price ||
        String(tariff.limits.autocast_emails_count) !== this.tariff.limits.autocast_emails_count ||
        String(tariff.limits.mass_emails_count) !== this.tariff.limits.mass_emails_count ||
        !this.compareStringArray(
          this.tariff.default_instruments.forms,
          tariff.default_instruments.forms
        ) ||
        !this.compareStringArray(
          this.tariff.default_instruments.alerts,
          tariff.default_instruments.alerts
        ) ||
        !this.compareStringArray(
          this.tariff.default_instruments.autocasts,
          tariff.default_instruments.autocasts
        ) ||
        !this.compareStringArray(
          this.tariff.default_instruments.widgets,
          tariff.default_instruments.widgets
        ) ||
        !this.compareStringArray(this.tariff.permissions, tariff.permissions)
      ) {
        if (tariff.name !== this.tariff.name) {
          this.update_request_body.name = this.tariff.name
        } else {
          delete this.update_request_body.name
        }

        if (tariff.region !== this.tariff.region) {
          this.update_request_body.region = this.tariff.region
        } else {
          delete this.update_request_body.region
        }

        if (tariff.currency !== this.tariff.currency) {
          this.update_request_body.currency = this.tariff.currency
        } else {
          delete this.update_request_body.currency
        }

        if (String(tariff.price) !== this.tariff.price) {
          this.update_request_body.price = Number(this.tariff.price)
        } else {
          delete this.update_request_body.price
        }

        if (
          String(tariff.limits.autocast_emails_count) !==
            this.tariff.limits.autocast_emails_count ||
          String(tariff.limits.mass_emails_count) !== this.tariff.limits.mass_emails_count
        ) {
          this.update_request_body.limits.autocast_emails_count = Number(
            this.tariff.limits.autocast_emails_count
          )
          this.update_request_body.limits.mass_emails_count = Number(
            this.tariff.limits.mass_emails_count
          )
        } else {
          delete this.update_request_body.limits
        }

        if (!this.compareStringArray(this.tariff.permissions, tariff.permissions)) {
          this.update_request_body.permissions = this.tariff.permissions.map(el => el)
        } else {
          delete this.update_request_body.permissions
        }

        if (
          !this.compareStringArray(
            this.tariff.default_instruments.forms,
            tariff.default_instruments.forms
          ) ||
          !this.compareStringArray(
            this.tariff.default_instruments.alerts,
            tariff.default_instruments.alerts
          ) ||
          !this.compareStringArray(
            this.tariff.default_instruments.autocasts,
            tariff.default_instruments.autocasts
          ) ||
          !this.compareStringArray(
            this.tariff.default_instruments.widgets,
            tariff.default_instruments.widgets
          )
        ) {
          this.update_request_body.default_instruments = JSON.parse(
            JSON.stringify(this.tariff.default_instruments)
          )
        } else {
          delete this.update_request_body.default_instruments
        }

        this.show_update_modal = true
      } else {
        this.$message('Нет необходимости обновления', 'info')
      }
    },
    async updateRquest() {
      this.show_update_modal = false
      this.isLoading = true
      console.log(this.update_request_body)
      await this.$store.dispatch('update_tariff', {
        body: this.update_request_body,
        id: this.$route.params.tariff_id
      })
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
.tariff-wrapper {
  .tariff-body-content {
    margin-top: 20px;
    border-radius: 4px;
    padding: 15px;
    border: 1px solid #dbdbdb;

    .item-title {
      margin-bottom: 5px;
    }
    .item-input-wrapper {
      width: 300px;
      margin-bottom: 10px;

      .dropdown-wrapper {
        .dropdown-trigger {
          width: 300px;
        }
        .select-input.error {
          border-color: #ff3860;
          color: #ff3860;
        }
      }
      .select-input {
        padding-right: 30px;
      }
    }
    .item-group-wrapper {
      margin: 10px 0;

      .item-input-wrapper {
        margin-left: 10px;
      }
    }
    .save-button {
      margin-top: 10px;
    }
    .error-message {
      font-size: 12px;
      color: #ff3860;
    }
    .field {
      margin: 0;
    }

    .loading-overlay {
      position: relative;
      height: 200px;

      .loading-background {
        position: relative;
      }
    }
  }

  .control.has-icons-right .input {
    padding-right: 9px;
  }

  .modal-content {
    width: 500px !important;

    .update-modal-wrapper {
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .update-modal-text {
        text-align: center;
        font-size: 18px;
      }
      .buttons-wrapper {
        display: flex;
        justify-content: center;

        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
